.leftLogoBox {
  @media screen and (max-width: 1681px) {
    background-size: 80%;
  }
  @media screen and (max-width: 641px) {
    background-size: 240px;
    background-position: top;
  }
  @media screen and (max-width: 426px) {
    background-size: 88%;
  }
}
.companylogo {
  @media screen and (max-width: 1441px) {
    width: 80%;
  }
  @media screen and (max-width: 641px) {
    width: 50%;
  }
  @media screen and (max-width: 481px) {
    width: 280px;
  }}
.rightLoginBox,.signinform {
  @media screen and (max-width: 641px) {
    height: auto;
  }
}
.signinform {
  @media screen and (max-width: 641px) {
    margin-top: 50px;
  }
  @media screen and (max-width: 484px) {
    margin-top: 36px;
    padding-bottom: 36px;
  }
}
.customCheckbox {
  display: block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      left: 0;
  }
}
.checkmark {
  position: absolute;
  top: 40%;
  left: 10px;
  height: 24px;
  width: 24px;
  border: 1px solid #DCE6F0;
  background-color: white;
  border-radius: 4px;
  transform: translate(-50%,-50%);
}
.customCheckbox input:checked ~ .checkmark {
  background-color: #2196f3;
  border-radius: 4px;
}
.checkmark:after {  
  content: "";
  position: absolute;
  display: none;
}
.customCheckbox input:checked ~ .checkmark:after {
  display: block;
}

.customCheckbox .checkmark:after {
  left: 30%;
  top: 50%;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg) translate(-50%, -50%);
  -ms-transform: rotate(45deg) translate(-50%, -50%);
  transform: rotate(45deg) translate(-50%, -50%);
}
.theeye {
  transform: translate(-50%,-50%);
}
.loginButtonsContainer{
  display: flex;
  align-items: center;
  &::after,
  &::before{
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #C4C4C4;
  }
  &::before{
    margin-right: 8px;
  }
  &::after{
    margin-left: 8px;
  }
}
.customLoading{
  color: #00DB92 !important;
  width: 60px !important;
  height: 60px !important;
}