.job-table {
    width: 100%;
    border-collapse: collapse;
  
    thead {
      tr {
        background-color: #f3f4f6; // Light gray background
      }
  
      th {
        padding: 1rem;
        border: 1px solid #d1d5db; // Border color
        text-align: left;
        font-weight: 600;
      }
    }
  
    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f9fafb; // Alternate row color
        }
      }
  
      td {
        padding: 1rem;
        width: 50%;
        border: 1px solid #d1d5db; // Border color
        font-weight: 400;
        font-size: 0.875rem;
        color: #333;
        &:first-child{
          font-weight: 500;
        }
        @media screen and (max-width:641px) {
          display: block;
          width: 100%;
          &:first-child{
            border-bottom: 0;
            padding-bottom: 0.5rem;
            font-weight: 500;
          }
          &:last-child{
            border-top: 0 !important;
            border-left:1px solid #d1d5db;
            padding-top: 0.5rem;
          }
        }
      }
    }
  }
  