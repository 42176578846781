.leftLogoBox {
    @media screen and (max-width: 1681px) {
      background-size: 80%;
    }
    @media screen and (max-width: 641px) {
      background-size: 66%;
      background-position: center !important;
    }
    @media screen and (max-width: 426px) {
        background-size: 88%;
    }
  }
  .companylogo {
    @media screen and (max-width: 1441px) {
      width: 80%;
    }
    @media screen and (max-width: 641px) {
      width: 50%;
    }
    @media screen and (max-width: 481px) {
      width: 280px;
    }}
  .rightLoginBox,.signinform {
    @media screen and (max-width: 641px) {
      height: 100vh;
    }
  }
  .signinform {
    @media screen and (max-width: 641px) {
      margin-top: 50px;
    }
  }
