@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Cleaning Thirdparty CSS for Customizable UI

.react-datepicker__triangle {
  display: none;
}

.react-datepicker-wrapper {
  // width: fit-content;
}

.w-full .react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__week .react-datepicker__day--selected {
  background-color: #3699ff;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #464e5f;
  font-family: "Poppins", sans-serif;
}

.react-datepicker__header {
  background-color: #f3f6f9;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #464e5f;
  font-family: "Poppins", sans-serif;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  color: white;
}

// Resuable Component Classes

@layer components {
  .base-label {
    @apply text-13 sm:text-[15px] leading-[22px] font-medium text-black2 mr-2;
  }

  .base-label-sm {
    @apply text-12 sm:text-13 leading-[19.5px] font-medium text-black2 mr-2;
  }
}

.css-advny6-MuiPopper-root {
  z-index: 999999 !important;
}



// .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
//   padding: 12px 8px !important;
// }

// .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
//   margin-bottom: 10px !important;
// }

// .css-4jnixx-MuiStack-root>.MuiTextField-root {
//   min-width: 80px !important;
// }

// .css-4jnixx-MuiStack-root {
//   padding-top: 0 !important;
// }

// .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
//   display: none !important;
// }

// .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
//   padding-right: 0 !important;
//   max-width: 70px !important;
// }

// .css-4jnixx-MuiStack-root {
//   overflow: unset !important;
// }
// .tox-tinymce-aux{
//   z-index: 99999 !important;
// }
.tox-tinymce-aux .tox-notifications-container{
  display: none !important;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  width: 0;
  height: 0;
}


.editorWrapper { 
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color:#858795;
  font-weight: 400;
  text-align: left;
  
  & > *{
      margin-bottom: 16px;
  }
  
  h1,h2,h3,h4,h5,h6 {
      color:#464E5F;
      font-weight: 600;
      margin-bottom: 20px;
  }

  ul {
      list-style: disc;
      padding-left: 12px;
  }
}