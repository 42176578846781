.leaveTable{
    td{
        font-weight: normal;
    }
    th[id="headeractions"],
    th[id="headerlasted"],
    td[id*="actions"],
    td[id*="lasted"]{
        min-width: 140px !important;
        max-width: 140px !important;
    }
    
    input{
        width: 100%;
    }
    td[id*="reason"],
    td[id*="appliedAt"],
    td[id*="employeeName"],
    td[id*="from"],
    td[id*="to"],
    td[id*="total"],
    td[id*="status"]{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
.customSharedBtn{
    &:hover{
        svg path{
            stroke: #4FC9DA;
        }
    }
}
.customCloseIcon{
    &:hover{
        svg path{
            stroke: #EE436D;
        }
    }
}