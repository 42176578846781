.customSwitch {
  &::before {
    position: absolute;
    display: block;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 100%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

.customSwitchContainer {
  display: flex;
  align-items: center;
  height: 100%;

  input:checked+.customSwitch {
    background-color: #2196F3;
  }

  input:focus+.customSwitch {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked+.customSwitch:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
}

.PermissionTable {
  th {
    white-space: normal;
    // max-width: 200px;
    // width: 200px;
    color: #858795 !important;
    border-left: 0;
    border-right: 0;
  }

  th[id*="permission"] {
    border-bottom: 1px solid #C4C4C4;
  }

  td[id*="permission"],
  th[id*="permission"] {
     max-width: 280px !important;
     min-width: 280px !important;
    position: sticky;
    // width: 100%;
    left: 0;
    z-index: 1;
    background-color: #fff;
    min-height: 51px;
    // height: 100%;
  }

  th[id*="permission"]>div {
    height: 52px;
  }

  // td[id*="none0"],
  // th[id*="none0"]{
  //   width: 220px !important;
  //   min-width: 220px !important;
  //   display: table-cell !important;

  // }
  td {
    p {
      //height: 100%;
      height: auto;
      display: inline-block;
      align-items: center;
    }

    // width: 200px;
    // min-height: 69px !important;
    //height: 69px !important;
  }

  th,
  td {
    max-width: 132px !important;
  }

  th {
    height: 52px;
  }
}

.permissionDropdown {
  >div {
    min-width: 250px;

    @media screen and (max-width:481px) {
      min-width: max-content;
    }
  }

  button {
    max-width: 100%;
    width: 100%;
    padding: 5px 11px;
    overflow: hidden;

    @media screen and (max-width:481px) {
      max-width: 100%;
      width: 100%;
      word-break: break-word;
      text-align: left;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media screen and (max-width:481px) {
    width: 100%;

    >div {
      width: 100%;
    }
  }
}

.progress {
  svg {
    height: 90vh;
    margin: auto;
    display: block;
  }

  path {
    stroke-linecap: round;
    stroke-width: 6;
  }

  path.grey {
    stroke: #e7e7e8;
  }

  path.blue {
    stroke: url(#gradient);
    stroke-dasharray: 198;
    stroke-dashoffset: 198;
    animation: dash 3s ease-out forwards;
  }
}

.userTables td>div>div {
  max-width: 100% !important;
}

.userTables {
  td[id*="roles"] {
    padding: 0 !important;
  }
}

.userTables {
  td[id*="action"] {
    min-height: 35px !important;
    max-height: 38px !important;
    height: 38px !important
  }
}

.userTables {
  td {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.projectTable {
  position: relative;

  td {
      font-weight: normal;
      // width: 200px;
      // min-width: 200px;
      // max-width: 249px;
      left: unset;

      >div {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          height: 100%;

          >div {
              height: 100%;
              display: flex;
              align-items: center;
              padding-top: 0 !important;
              padding-bottom: 0 !important;
              max-height: 35px !important;
          }
      }

      // >div:first-child{
      //    // min-width: 100% !important;
      //   //  max-width: 100% !important;
      // }
  }

  td[id*="race"] {
      >div>div {
          display: flex;
          align-items: center;
      }
  }

  // td[id*="gender"]{
  //     max-width: 100px !important;
  //     min-width: 100px !important;
  //     >div>div{
  //         max-width: 100% !important;
  //     }
  // }
  // td[id*="nationality"],
  // td[id*="status"]{
  //     max-width: 130px !important;
  //     min-width: 130px !important;
  //     >div>div{
  //         max-width: 100% !important;
  //     }
  // }
  // td[id*="designation"],
  // td[id*="currency_id"],   
  // td[id*="department"]{
  //     max-width: 218px !important;
  //     min-width: 218px !important;
  //     >div>div{
  //         max-width: 100% !important;
  //     }
  // }
  // th {
  //     // width: 200px;
  //     // >div{
  //     //    // max-height: 43px !important;
  //     // }
  // }
  th[id*="actions"] {
      >div {
          max-height: 45px !important;
      }
  }

  // input[type="number"],
  // input[type="email"]{
  //    // background-color: transparent;
  // }
  input[type="text"] {
      width: 100%;
      // background-color: transparent;
  }

  th[id*="lasted"],
  td[id*="lasted"] {
      min-width: 141px !important;
      width: 141px;

      >div {
          height: 100%;
      }
  }

  th[id*="actions"],
  td[id*="actions"] {
      min-width: 140px !important;
      width: 140px;
      position: absolute !important;
      right: 0;
      left: unset !important;
      background-color: #fff;
      z-index: 1;
  }

  //   td[id*="employeeID"],
  //   td[id*="age"]{
  //       padding-left: 0 !important;
  //       padding-right: 0 !important;
  //   }
}

.userTable {
  position: relative;

  td {
      font-weight: normal;
      // width: 200px;
      // min-width: 200px;
      // max-width: 249px;
      left: unset;

      >div {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          height: 100%;

          >div {
              height: 100%;
              display: flex;
              align-items: center;
              padding-top: 0 !important;
              padding-bottom: 0 !important;
              max-height: 35px !important;
          }
      }

      // >div:first-child{
      //    // min-width: 100% !important;
      //   //  max-width: 100% !important;
      // }
  }

  td[id*="race"] {
      >div>div {
          display: flex;
          align-items: center;
      }
  }

  // td[id*="gender"]{
  //     max-width: 100px !important;
  //     min-width: 100px !important;
  //     >div>div{
  //         max-width: 100% !important;
  //     }
  // }
  // td[id*="nationality"],
  // td[id*="status"]{
  //     max-width: 130px !important;
  //     min-width: 130px !important;
  //     >div>div{
  //         max-width: 100% !important;
  //     }
  // }
  // td[id*="designation"],
  // td[id*="currency_id"],   
  // td[id*="department"]{
  //     max-width: 218px !important;
  //     min-width: 218px !important;
  //     >div>div{
  //         max-width: 100% !important;
  //     }
  // }
  // th {
  //     // width: 200px;
  //     // >div{
  //     //    // max-height: 43px !important;
  //     // }
  // }
  th[id*="actions"] {
      >div {
          max-height: 45px !important;
      }
  }

  // input[type="number"],
  // input[type="email"]{
  //    // background-color: transparent;
  // }
  input[type="text"] {
      width: 100%;
      // background-color: transparent;
  }

  th[id*="lasted"],
  td[id*="lasted"] {
      min-width: 141px !important;
      width: 141px;

      >div {
          height: 100%;
      }
  }

  th[id*="actions"],
  td[id*="actions"] {
      min-width: 151px !important;
      width: 151px !important;
      position: sticky !important; //absolute
      right: 0;
      left: unset !important;
      background-color: #fff;
      z-index: 1;
  }

  td[id*="employeeID"],
  td[id*="age"] {
      padding-left: 0 !important;
      padding-right: 0 !important;
  }
}

.selectedMember {
  background-color: #E1F0FF !important;
}

.singleDropdown {
  width: 100%;
}

.projectTable {
  td>div>button {
      height: 100% !important;
  }

  td[id*="projectCode"],
  td[id*="projectCompleted"] {
      padding-left: 0 !important;
      padding-right: 0 !important;
  }
}

.customCloseIcon {
  &:hover {
      svg path {
          stroke: #EE436D;
      }
  }
}


.updatedProjectBar {
  // position: relative;

  &::after {
      display: block;
      content: '';
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      border-left: 18px solid transparent;
      border-top: 18px solid #FFC84A;
      border-right: 8px solid #FFC84A;
      border-bottom: 18px solid #FFC84A;
      position: absolute;
      top: -1;
      left: 70;
      z-index: 1,
  }
}
.customCloseIcon{
&:hover{
    svg path{
        stroke: #EE436D;
    }
}
}
.customUploadBtn{
  background-color: rgba(0, 0, 0, 0.2);
  svg{
    width: 100%;
    height: 100%;
    transform: scale(3);
  }
}
.profileImageDiv{
  &:hover{
    .customUploadBtn{
      display: block;
    }
  }
}
