.flexContainer {
  margin-top: -16px;
  & > div {
    margin-top: 16px;
  }
}

.customWFull {
  & > div {
    width: 100%;
  }
}

.dropdownFillAvailable {
  @media screen and (max-width: 1440px) {
    & > div {
      width: 100%;
    }
  }
}

.customSwitch {
  &::before {
      position: absolute;
      display: block;
      content: "";
      height: 22px;
      width: 22px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      border-radius: 100%;
      -webkit-transition: 0.4s;
      transition: 0.4s;
  }
}

.customSwitchContainer{
  input:checked + .customSwitch {
      background-color: #2196F3;
    }
    
    input:focus + .customSwitch {
      box-shadow: 0 0 1px #2196F3;
    }
    
    input:checked + .customSwitch:before {
      -webkit-transform: translateX(18px);
      -ms-transform: translateX(18px);
      transform: translateX(18px);
    }
}

.permissionDropdown{
button{
  min-width: 220px;
}
span{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
}
