.informationcard{    
    &__card {
        grid-column-gap: 15px;
        @media screen and (max-width: 1025px) {
            align-items: stretch !important;
        }
        @media screen and (max-width:639px) {
            flex-wrap: wrap;
        }
        &__item {
            @media screen and (max-width: 639px) {
                width: 48%;
                margin-bottom: 15px;
            }
            @media screen and (max-width: 638px) {
               // width: 47%;
               width: 100%;
            }
            @media screen and (max-width: 376px) {
                //width: 46%;
            }
        }
    }
}