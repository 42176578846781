.cautionDiv {
    &::before {
      display: block;
      content: '';
      background-color: #1BC5BD;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }
  
  .noticeDiv {
    &::before {
      display: block;
      content: '';
      background-color: #FFA800;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }
  
  .warningDiv {
    &::before {
      display: block;
      content: '';
      background-color: #F64E60;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }
  